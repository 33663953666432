import styles from "./Users.module.scss";
import formStyles from "@acmos/ui/src/assets/layout/form.module.scss";
import { useEffect, useState } from "react";
import { CloudModels } from "@acmos/models";
import { useCompanyStore } from "../Companies/store";
import { Button, cloudInstance, TightSpace } from "@acmos/ui";
import UserCard from "../../components/UserCard/UserCard";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const companyStore = useCompanyStore((s) => s);

  useEffect(() => {
    return CloudModels.User.subscribeAll(
      cloudInstance.db,
      [[`companies.${companyStore.current?.id}.active`, "==", true]],
      setUsers,
    );
  }, [companyStore]);

  const addUser = () => {
    if (!companyStore.current?.id) return;
    navigate("/user");
  };

  return (
    <div className={[styles.Container, formStyles.FormContainer].join(" ")}>
      {!!companyStore.current.id && (
        <div className={formStyles.Users}>
          <div className={formStyles.Header}>
            Usuários
            <TightSpace />
            <Button onClick={addUser} leftIcon={"plus"}>
              Adicionar
            </Button>
          </div>

          <TightSpace />
          <div>
            {users.map((user) => {
              return <UserCard user={user} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default Users;
