import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './Sidebar.scss';

const Sidebar = ({ children, menuIconContent }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div
      className={[styles.Container, !isOpened ? styles.Closed : ''].join(' ')}
    >
      <div className={[styles.Item, styles.Toggler].join(' ')}>
        <div className={styles.Icon} onClick={() => setIsOpened(!isOpened)}>
          <Icon icon='bars' />
        </div>
        <div className={styles.Text}>{menuIconContent}</div>
      </div>
      {children}
    </div>
  );
};

export default Sidebar;
