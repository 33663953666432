import { Button, TightSpace } from "@acmos/ui";
import { useNavigate } from "react-router-dom";
import { useCompanyStore } from "../../screens/Companies/store";
import styles from "./CompanyCard.module.scss";

const CompanyCard = ({ company }) => {
  const companyStore = useCompanyStore((s) => s);
  const navigate = useNavigate();

  return (
    <div className={styles.Container}>
      <div className={styles.Name}>
        <div className={styles.Text}>{company.name}</div>

        <div className={styles.Id}>{company.id}</div>
      </div>

      <div>
        <div className={styles.Actions}>
          <Button
            extraClass={styles.Button}
            onClick={() => {
              companyStore.setCurrent(company);
              navigate("/company/" + company.id);
            }}
          >
            Abrir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
