import React from 'react';
import Icon from '../Icon/Icon';
import styles from './Sidebar.scss';

const SidebarItem = ({ leftIcon, rightIcon, text, onClick, ...rest }) => {
  return (
    <div className={styles.Item} onClick={onClick} {...rest}>
      <div className={styles.Icon}>
        <Icon icon={leftIcon} />
      </div>
      <div className={styles.Text}>{text}</div>
      {!!rightIcon && (
        <div className={styles.Icon}>
          <Icon icon={rightIcon} />
        </div>
      )}
    </div>
  );
};
export default SidebarItem;
