import { CloudModels } from "@acmos/models";
import { Button, Input, AppFunctions, cloudInstance } from "@acmos/ui";
import { useContext, useEffect, useState } from "react";
import { i18nContext } from "../../i18n";
import styles from "./Users.module.scss";
import formStyles from "@acmos/ui/src/assets/layout/form.module.scss";
import validate from "validate.js";
import { useCompanyStore } from "../Companies/store";

const User = ({}) => {
  const [existent, setExistent] = useState(false);
  const [user, setUser] = useState(new CloudModels.User());
  const [userError, setUserError] = useState(new CloudModels.User());
  const [password, setPassword] = useState("");
  const { i18n } = useContext(i18nContext);
  const companyStore = useCompanyStore((s) => s);

  useEffect(() => {
    setExistent(false);
    if (!validate.single(user.email, { email: true, presence: true })) {
      CloudModels.User.fetchAll(cloudInstance.db, [
        ["email", "==", user.email],
      ]).then((users) => {
        if (!users.length) return;
        setExistent(true);
        setUser(users[0]);
      });
    }
  }, [user?.email, companyStore]);

  const setUserFromObject = (user) => {
    setUser(new CloudModels.User().fillFromObject(user));
  };

  const onChange = (prop) => (e) => {
    user[prop] = e.target.value;
    setUser(new CloudModels.User().fillFromObject(user));
  };
  const createUser = () => {
    AppFunctions.cloud
      .createUser({
        user,
        password,
        company: companyStore.current,
      })
      .then(setUserFromObject);
  };
  const updateUser = () => {
    AppFunctions.cloud.updateUser(user);
  };
  const disabledExistent =
    existent && !user.companies?.[companyStore.current?.uid];

  return (
    <div className={formStyles.FormContainer}>
      <div className={formStyles.Form}>
        <div className={formStyles.Header}>
          Cadastro de Usuário
          {!!user.id && <div className={formStyles.Id}>{user.id}</div>}
        </div>
        {disabledExistent && (
          <div className={formStyles.Header}>
            Usuário já adicionado em outra empresa. Isto não impede de
            adicioná-lo neste empresa também, porém seus dados não podem ser
            modificados aqui.
          </div>
        )}
        <div>
          {/* TODO - MOVE ALL TYPES OF VALIDATION INSIDE UI PACKAGE */}
          <Input
            label={i18n("user.email")}
            disabled={disabledExistent}
            value={user.email}
            validation={(value) =>
              validate.single(value, { email: true, presence: true })
            }
            onChange={onChange("email")}
          />
          {!disabledExistent && (
            <>
              <Input
                label={i18n("user.displayName")}
                disabled={disabledExistent}
                value={user.displayName}
                onChange={onChange("displayName")}
              />
              <Input
                label={i18n("user.phoneNumber")}
                value={user.phoneNumber}
                onChange={onChange("phoneNumber")}
              />
              <Input
                label={i18n("user.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          )}
          <Button onClick={!user.id ? createUser : updateUser}>Salvar</Button>
        </div>
      </div>
    </div>
  );
};
export default User;
