import {
  Toast,
  AppBar,
  PrivateScreen,
  Input,
  FlexSpace,
  Sidebar,
  SidebarItem,
  authService,
  APPS,
} from "@acmos/ui";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./screens/Home/Home";
import "@acmos/ui/src/assets/layout/_default.scss";
import styles from "./App.module.scss";
import { ReactComponent as CloudLogo } from "@acmos/ui/src/assets/images/cloud/logo-horiz-blue.svg";
import {
  browserLanguage,
  format,
  i18n,
  languagesList,
  setCurrentLanguage,
} from "./i18n";
import React, { useContext, useState } from "react";
import { i18nContext } from "./i18n";
import Companies from "./screens/Companies/Companies";
import Company from "./screens/Companies/Company";
import User from "./screens/Users/User";
import Users from "./screens/Users/Users";
import { useCompanyStore } from "./screens/Companies/store";

const auth = authService;
auth.checkAuthState();

export const ToastContext = React.createContext({
  toasts: [],
  addToast: () => {},
});

function App() {
  const [_currentLanguage, _setCurrentLanguage] = useState(browserLanguage);
  const [_currentLanguageJSON, _setCurrentLanguageJSON] = useState(
    setCurrentLanguage(browserLanguage),
  );
  const [toasts, setToasts] = useState([]);

  const addToast = (content, timeout) => {
    const toast = {
      content,
      timeout: -1,
      stopTimeout: () => {
        clearTimeout(toast.timeout);
      },
      startTimeout: () => {
        toast.stopTimeout();
        toast.timeout = setTimeout(() => {
          let index = toasts.findIndex((o) => o === content);
          if (index) {
            toasts.splice(index, 1);
            setToasts([...toasts]);
          }
        }, timeout || 10000);
      },
    };
    // toast.startTimeout();
    toasts.push(toast);
    setToasts([...toasts]);
  };

  const currentUser = auth.useAuthStore((s) => s.currentUser);
  const companyStore = useCompanyStore((s) => s);

  return (
    <i18nContext.Provider
      value={{
        i18n: i18n(_currentLanguageJSON),
        format: format(_currentLanguageJSON),
      }}
    >
      <ToastContext.Provider value={{ toasts, addToast }}>
        <div className={styles.App}>
          {!!currentUser?.uid && (
            <SidebarContent
              _currentLanguage={_currentLanguage}
              _setCurrentLanguage={_setCurrentLanguage}
              _setCurrentLanguageJSON={_setCurrentLanguageJSON}
            />
          )}
          <div className={styles.Container}>
            {!!currentUser?.uid && (
              <AppBar auth={auth} logo={<CloudLogo />} homePageLink={"/"}>
                {companyStore.current?.name}
              </AppBar>
            )}
            <Routes>
              <Route
                path="/company/:id"
                element={
                  <PrivateScreen
                    ScreenComponent={Company}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
              <Route
                path="/company"
                element={
                  <PrivateScreen
                    ScreenComponent={Company}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
              <Route
                path="/companies"
                element={
                  <PrivateScreen
                    ScreenComponent={Companies}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />

              <Route
                path="/users/"
                element={
                  <PrivateScreen
                    ScreenComponent={Users}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
              <Route
                path="/user/:id"
                element={
                  <PrivateScreen
                    ScreenComponent={User}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
              <Route
                path="/user"
                element={
                  <PrivateScreen
                    ScreenComponent={User}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <PrivateScreen
                    ScreenComponent={Home}
                    isAuthenticated={!!currentUser.uid}
                    auth={auth}
                    app={APPS.CLOUD}
                  />
                }
              />
            </Routes>
          </div>
        </div>
        {toasts.map((toast) => {
          return (
            <Toast
              onMouseEnter={toast.stopTimeout}
              onMouseLeave={toast.startTimeout}
            >
              {toast.content}
            </Toast>
          );
        })}
      </ToastContext.Provider>
    </i18nContext.Provider>
  );
}

const SidebarContent = ({
  _currentLanguage,
  _setCurrentLanguage,
  _setCurrentLanguageJSON,
}) => {
  const { i18n } = useContext(i18nContext);
  const navigate = useNavigate();
  return (
    <Sidebar
      menuIconContent={
        <Input
          value={_currentLanguage}
          onChange={(id) => {
            _setCurrentLanguage(id);
            _setCurrentLanguageJSON(setCurrentLanguage(id));
          }}
          selectedItemProp={"id"}
          items={languagesList}
          itemDisplayProps={["name"]}
          itemRender={(item) => {
            return (
              <div>
                {item.name} <FlexSpace />
                {item.id}
              </div>
            );
          }}
          containerExtraClass={styles.LanguageSelector}
        />
      }
    >
      <SidebarItem
        onClick={() => navigate("/companies")}
        leftIcon="building"
        text={i18n("project.menu.companies")}
      />
      <SidebarItem
        onClick={() => navigate("/roles")}
        leftIcon="user-gear"
        text={i18n("project.menu.roles")}
      />
      <SidebarItem
        onClick={() => navigate("/users")}
        leftIcon="users"
        text={i18n("project.menu.users")}
      />
    </Sidebar>
  );
};

export default App;
