import create from "zustand";
import { persist } from "zustand/middleware";
export const useCompanyStore = create(
  persist(
    (set, get) => ({
      current: {},
      setCurrent: (company) => {
        set((state) => (state.current = company));
      },
    }),
    {
      name: "company-storage",
    },
  ),
);
