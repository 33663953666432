import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Companies.module.scss";
import formStyles from "@acmos/ui/src/assets/layout/form.module.scss";
import { AppFunctions, Button, cloudInstance, TightSpace } from "@acmos/ui";
import { useCompanyStore } from "./store";
import { CloudModels } from "@acmos/models";
import CompanyCard from "../../components/CompanyCard/CompanyCard";

const Companies = ({}) => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    return CloudModels.Company.subscribeAll(cloudInstance.db, [], (list) => {
      setCompanies(list.sortByKey((o) => [o.name]));
    });
  }, []);

  const addCompany = () => {
    navigate("/company");
  };

  return (
    <div className={[formStyles.FormContainer, styles.Container].join(" ")}>
      <div className={formStyles.Header}>
        Empresas
        <TightSpace />
        <Button onClick={addCompany}>Adicionar</Button>
      </div>
      <div className={styles.List}>
        {companies.map((company) => {
          return <CompanyCard company={company} />;
        })}
      </div>
    </div>
  );
};

export default Companies;
