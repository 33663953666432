import { CloudModels, DomoModels } from "@acmos/models";
import { cloudInstance, Input, numberCustomMask, TightSpace } from "@acmos/ui";
import { useEffect, useState } from "react";
import formStyles from "@acmos/ui/src/assets/layout/form.module.scss";
import styles from "./Companies.module.scss";

import { AppFunctions, Button } from "@acmos/ui";
import { useParams } from "react-router-dom";

const Company = ({}) => {
  const [company, setCompany] = useState(new CloudModels.Company());
  const params = useParams();

  const setCompanyFromObject = (company) => {
    setCompany(new CloudModels.Company().fillFromObject(company));
  };

  useEffect(() => {
    if (!params.id) return;
    let company = new CloudModels.Company();
    company.fetch(cloudInstance.db, params.id).then((r) => {
      setCompany(company);
    });
  }, [params.id]);

  const onChange = (prop) => (e) => {
    company[prop] = e.target.value;
  };

  const addCompany = () => {
    AppFunctions.cloud.createCompany(company).then(setCompanyFromObject);
  };

  const saveCompany = () => {
    AppFunctions.cloud.updateCompany(company).then((company) => {
      setCompany(new CloudModels.Company().fillFromObject(company));
    });
  };

  return (
    <div className={formStyles.FormContainer}>
      <div className={formStyles.Form}>
        <div className={formStyles.Header}>
          Cadastro de Empresa
          {!!company.id && <div className={formStyles.Id}>{company.id}</div>}
        </div>
        <div>
          <Input
            label="Nome"
            value={company.name}
            onChange={onChange("name")}
          />
          <Input
            label="Descrição"
            value={company.description}
            onChange={onChange("description")}
          />
          <Input
            label="CNPJ"
            value={company.legalDocument}
            interceptValueChange={(value) => {
              return numberCustomMask(
                "XX.XXX.XXX/XXXX-XX",
                value.replace(/[^\d]/gi, ""),
              );
            }}
            onChange={onChange("legalDocument")}
          />
          <Button onClick={company.id ? saveCompany : addCompany}>
            Salvar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Company;
