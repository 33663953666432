import styles from "./UserCard.module.scss";

const UserCard = ({ user }) => {
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.Avatar}>
          {user.displayName.split(" ").map((o) => o[0].toUpperCase())}
        </div>
        <div>
          <div className={styles.Name}>{user.displayName}</div>
          <div className={styles.Email}>{user.email}</div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
