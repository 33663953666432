import {
  BaseSpace,
  Button,
  Content,
  Sidebar,
  SidebarItem,
  Text,
  TEXT_SIZES,
} from "@acmos/ui";
import { domoInstance, DomoModels } from "@acmos/models";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { i18nContext } from "../../i18n";

import styles from "./Home.module.scss";

const Home = () => {
  const { i18n } = useContext(i18nContext);
  return <Content></Content>;
};

export default Home;
