import ptbr from "./translations/pt-br.json";
import enus from "./translations/en-us.json";

import { getCookieValue, setCookieValue } from "@acmos/ui";
import React, { useState } from "react";

const IntlPolyfill = Intl;

const languages = {
  ptbr,
  enus,
};
export const languagesList = [
  { id: "pt-br", name: "Português" },
  { id: "en-us", name: "English" },
];

const cookieName = "i18n-language";

export const setCurrentLanguage = (language) => {
  setCookieValue(cookieName, language);
  return languages[language.replace(/-/gi, "").toLowerCase()] || languages.enus;
};

export const browserLanguage =
  getCookieValue(cookieName) || navigator.language || navigator.userLanguage;
let current =
  languages[browserLanguage.replace(/-/gi, "").toLowerCase()] || languages.enus;

export const i18n = (current) => (key, replaceMatrix) => {
  let message = current[key];
  if (message === undefined) return key;
  if (replaceMatrix instanceof Array && typeof message === "string") {
    for (let i = 0; i < replaceMatrix.length; i++) {
      message = message.replace("@", replaceMatrix[i]);
    }
  }
  if (
    typeof replaceMatrix === "object" &&
    !(replaceMatrix instanceof Array) &&
    typeof message === "string"
  ) {
    for (let i in replaceMatrix) {
      if (!replaceMatrix.hasOwnProperty(i)) continue;
      message = message.replace(new RegExp("@" + i, "g"), replaceMatrix[i]);
    }
  }
  return message;
};

export const format = (current) => ({
  number: (n, d = 2) => {
    return new IntlPolyfill.NumberFormat(browserLanguage, {
      maximumFractionDigits: 2,
    }).format(n);
  },
  currency: (n, d = 2) => {
    return new IntlPolyfill.NumberFormat(browserLanguage, {
      style: "currency",
      currency: current.default.currency.abbreviation,
    }).format(n);
  },
  date: (d, o) => {
    if (!o) {
      o = current.default.date;
    }
    return new IntlPolyfill.DateTimeFormat(browserLanguage, o).format(d);
  },
  datetime: (d, o) => {
    if (!o) {
      o = current.default.datetime;
    }
    return new IntlPolyfill.DateTimeFormat(browserLanguage, o).format(d);
  },
});

export const i18nContext = React.createContext({
  i18n: i18n(current),
  format: format(current),
});
